(function ($, Drupal) {

  $(document).foundation();

  Drupal.behaviors.terramotriz = {
    attach: function(context, settings) {
      $('#logo-animate').addClass('show');
      $( ".node-contacto .wraper-slogan").removeClass('wow').removeClass('fadeIn');
      $( ".node-contacto .wraper-slogan").removeAttr('data-wow-duration').removeAttr('data-wow-delay');
    }
  };

  $(document).ready(function() {
    new WOW().init();

		$('.hamburger').click(function(event){
			if($('#nav-icon').hasClass('open')) {
				$('.availability-block-wrapper').css('position', '');
				$('.availability-block-wrapper').removeClass('js-avl-open');
				$('.availability-block-wrapper').addClass('js-avl-closed');
				$('#topbar').removeClass('topbar-open-bookings');
				$('.menu').removeClass('menu-open');
				$('.menu').addClass('menu-closed');
				$('#menu-wrapper').removeClass('menu-wrapper-open');
				$('#menu-wrapper').addClass('menu-wrapper-closed');
				$('#topbar').removeClass('topbar-open');
				$('#topbar').addClass('topbar-closed');
				$('#nav-icon').removeClass('open');
				$('#document').removeClass('js-document-opened');
				$('#document').addClass('js-document-closed');
				$('.hamburger').removeClass('hamburger-open');
				$('.hamburger').addClass('hamburger-closed');
				$('.availability-block-wrapper').removeClass('js-menu-open');
			}else{
				$('.menu').removeClass('menu-closed');
				$('.menu').addClass('menu-open');
				$('#menu-wrapper').removeClass('menu-wrapper-closed');
				$('#menu-wrapper').addClass('menu-wrapper-open');
				$('#topbar').addClass('topbar-open');
				$('#topbar').removeClass('topbar-closed');
				$('#topbar').addClass('topbar-open');
				$('#nav-icon').addClass('open');
				$('#document').removeClass('js-document-closed');
				$('#document').addClass('js-document-opened');
				$('.hamburger').removeClass('hamburger-closed');
				$('.hamburger').addClass('hamburger-open');
				$('.availability-block-wrapper').addClass('js-menu-open');
			}
		});

		$('.menu-links a').click(function(){

			$('.preloader-img').addClass('preloader-img-show');

			$(window).load(function() {
				setTimeout(function() {
					$('.menu').removeClass('menu-open');
					$('.menu').addClass('menu-closed');
					$('#nav-icon').removeClass('open');
					$('#menu-wrapper').removeClass('menu-wrapper-open');
					$('#menu-wrapper').addClass('menu-wrapper-closed');
					$('#topbar').removeClass('topbar-open');
					$('#topbar').addClass('topbar-closed');
					$('#nav-icon').removeClass('open');
					$('#document').removeClass('js-document-opened');
					$('#document').addClass('js-document-closed');
					$('.hamburger').removeClass('hamburger-open');
					$('.hamburger').addClass('hamburger-closed');
					$('.availability-block-wrapper').removeClass('js-menu-open');
					$('.preloader-img').removeClass('preloader-img-show');
				},100)
			});
		});

		$('.availability-block-wrapper button').click(function(event) {
			$(this).addClass('ajax-loading');
			$(window).load(function() {
				setTimeout(function() {
					$('.availability-block-wrapper').removeClass('js-menu-open');
					$('.availability-block-wrapper').removeClass('js-avl-open');
					$('.availability-block-wrapper').addClass('js-avl-closed');
					$('.availability-block-wrapper').css('position', '');
					$('#topbar').removeClass('topbar-open-bookings');
					$('#nav-icon').removeClass('open');
					$('.availability-block-wrapper button').removeClass('ajax-loading');
				},100)
			});
		});

});





})(jQuery, Drupal);
